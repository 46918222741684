@use "../base/variables" as v;
@use "../base/mixins" as m;

.dashboardHeader {
	background-color: v.$white;
	@include m.shadow();
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: v.$secondary;
	width: 100%;
	margin-bottom: 1.5rem;

	&__logo {
		color: v.$primary;
		margin: 1rem 2rem;
		font-weight: 400;
		font-style: italic;

		& span {
			font-weight: 900;
			font-size: 1.6rem;
		}
	}

	&__info {
		display: flex;
		align-items: center;
		font-size: 2rem;
		gap: 2rem;
		margin: 1rem 2rem;
	}

	&__userImage {
		border-radius: 50%;
		overflow: hidden;
		flex-basis: 5rem;
		max-width: 2.5rem;
		cursor: pointer;
	}

	&__icon {
		color: v.$secondary;
		cursor: pointer;
		transition: color 0.3s ease;

		&:hover {
			color: darken(v.$secondary, 10);
		}
	}
}
