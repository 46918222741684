@use "../base/variables" as v;
@use "../base/mixins" as m;

.editableTable {
	@include m.fadeIn(1000ms);
	@include m.shadow();

	display: flex;
	flex-direction: column;
	background-color: v.$white;
	border-radius: 0.5rem;
	overflow-x: scroll;
	max-height: calc(100vh - 21rem);
	position: relative;
	width: 100%;

	@include m.desktop {
		max-height: calc(100vh - 13rem);

		&--sidebarExpanded {
			max-width: calc(100vw - 26rem);
		}

		&--sidebarCollapsed {
			max-width: calc(100vw - 14rem);
		}

		@include m.transition(max-width, 300ms);
	}

	@include m.monitor {
		&--sidebarExpanded {
			width: 100%;
		}

		&--sidebarCollapsed {
			max-width: calc(100vw - 14rem);
		}
	}

	&__title {
		font-size: 2.2rem;
		font-weight: 400;
		margin-left: 5rem;
	}

	&__table {
		padding: 2rem;
		border-spacing: 0;
		font-size: 1.2rem;
		color: lighten($color: #000, $amount: 40);
		position: relative;

		td {
			border-bottom: solid 0.1rem lighten($color: v.$primary, $amount: 65);
		}
	}

	&__tableHeader {
		color: v.$secondary;
		background-color: v.$white;
		position: sticky;
		top: 0;
		z-index: 25;
	}

	&__grouper {
		color: v.$primary;
		padding-top: 2rem;
		padding-bottom: 1rem;
		border: solid 0.1rem lighten($color: v.$primary, $amount: 65);
		border-bottom: none;
	}

	&__headerCell {
		border-bottom: solid 0.1rem lighten($color: v.$primary, $amount: 65);
		text-align: center;
		vertical-align: top;
		padding: 1rem;

		&--id {
			position: sticky;
			left: 0;
			top: 0;
			background-color: v.$white;
		}
	}

	&__headerId {
		border-bottom: solid 0.1rem lighten($color: v.$primary, $amount: 65);
		text-align: center;
		vertical-align: top;
		padding: 1rem;

		position: sticky;
		left: 0;
		background-color: v.$white;
	}

	&__headerId {
		border-bottom: solid 0.1rem lighten($color: v.$primary, $amount: 65);
		text-align: center;
		vertical-align: top;
		padding: 1rem;
	}

	&__bodyCell {
		text-align: right;

		&--editable {
			text-align: right;
			cursor: pointer;
		}
	}

	&__cellId {
		position: sticky;
		left: 0;
		background-color: v.$white;
		text-align: center;
	}

	&__cellContent {
		padding: 1rem;
		width: 12rem;
	}

	tr &__footerCell {
		text-align: right;
		font-weight: 900;
		border-bottom: none;
		width: 1.2rem;
		padding: 3rem 1rem 2rem 1rem;
	}

	tr &__footerTotal {
		text-align: center;
		font-weight: 900;
		border-bottom: none;
		width: 1.2rem;
		padding: 3rem 1rem 2rem 1rem;
		position: sticky;
		left: 0;
		background-color: v.$white;
	}

	&__input {
		color: lighten($color: v.$primary, $amount: 40);
		font-variant-numeric: slashed-zero;
		border: none;
		padding: 1rem;
		border-radius: 0.5rem;
		width: 12rem;
		text-align: right;

		&::-webkit-outer-spin-button,
		&::-webkit-outer-inner-button {
			-webkit-appearance: none;
			margin: 0;
		}

		&:hover {
			background-color: lighten($color: v.$secondary, $amount: 40);
		}

		&:focus {
			background-color: lighten($color: v.$secondary, $amount: 30);
			color: lighten($color: v.$primary, $amount: 10);
			outline: none;
		}

		::-webkit-outer-spin-button,
		::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}

	&__saveButton {
		@include m.fadeIn(1000ms);
		@include m.shadow();
		@include m.button(lighten(v.$alert, 35), v.$alert);

		max-width: fit-content;
		position: sticky;
		bottom: 5rem;
		left: 2rem;
	}

	&__dataSavedMessage {
		@include m.fadeIn(1000ms);
		@include m.shadow();

		background-color: lighten(v.$success, 35);
		color: v.$success;
		font-size: 1.4rem;
		padding: 1.2rem 3rem;
		margin-top: 5rem;
		border-radius: 0.5rem;
		font-weight: 700;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		text-align: center;
		text-decoration: none;
		border: none;

		max-width: fit-content;
		position: sticky;
		bottom: 5rem;
		left: 2rem;
	}

	&__saveIcon {
		margin-left: 1rem;
		font-size: 1.8rem;
	}

	&__successIcon {
		font-size: 1.8rem;
	}
}
