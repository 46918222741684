@use "../base/variables" as v;
@use "../base/mixins" as m;

.newUserMessage {
	max-width: 40rem;
	margin: 5rem auto;
	text-align: center;

	&__title {
		color: v.$primary;
	}

	&__text {
		color: v.$textColor;
	}
}
