@use "../base/variables" as v;
@use "../base/mixins" as m;

.authFooter {
	&__text {
		font-size: 1.2rem;
		color: lighten(v.$primary, 40);

		span {
			color: v.$primary;
			font-weight: 700;
		}
	}
}
