@use "../../base/variables" as v;
@use "../../base/mixins" as m;

.section {
	@include m.fadeIn(1000ms);

	display: flex;
	flex-direction: column;
	gap: 2rem;
	margin-bottom: 6rem;

	@include m.desktop {
		margin-bottom: 2rem;
	}

	&__header {
		display: flex;
		flex-direction: column;
		gap: 2rem;
		align-items: center;

		@include m.desktop {
			flex-direction: row;
		}
	}

	&__title {
		margin: 0;
	}

	&__content {
		width: 100%;
	}
}
