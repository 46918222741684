// Fuentes
$mainFont: "Heebo", sans-serif;

// Tamaño de Media Queries
$mobile: 480px;
$tablet: 768px;
$desktop: 768px; //dev
// $desktop: 1024px; //production
$monitor: 1440px;

// Colores
// $primary: #667a8a;
// $secondary: #2196f3;

// $primary: #114358;
// $secondary: #f2aa1f;
// $black: #090909;
// $textColor: #090909;
// $textColor: #667a8a;
// $white: #fff;
// $appBackground: #f1ece7;

$primary: #2d3748;
$secondary: #3182ce;
$black: #000;
$textColor: #667a8a;
$white: #ffffff;
$appBackground: #fbfeff;
$danger: #cf4e46;
$alert: #cfaf1d;
$success: #4ccf27;

$spacing: 2rem;
