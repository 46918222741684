@use "../base/variables" as v;
@use "../base/mixins" as m;

.cycleSelector {
	background-color: v.$white;
	color: v.$textColor;
	cursor: pointer;
	font-size: 1.4rem;
	border: 0;
	outline: 0;

	&:hover {
		color: darken($color: v.$textColor, $amount: 40);
	}
}
