@use "../base/variables" as v;
@use "../base/mixins" as m;

.dashboardScreen {
	@include m.fadeIn(1000ms);

	display: flex;
	margin: 0 auto;
	background-color: v.$appBackground;
	height: 100%;

	&__main {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
}
