@use "../base/variables" as v;
@use "../base/mixins" as m;

.fileLink {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	border: 0.1rem solid lighten(v.$primary, 70);
	border-radius: 0.5rem;
	width: 100%;
	margin-bottom: 2rem;
	color: v.$primary;
	text-decoration: none;

	&__icon {
		font-size: 2rem;
		margin: 0 1rem;
	}

	a {
		color: unset;
	}
}
