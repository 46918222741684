@use "../base/variables" as v;
@use "../base/mixins" as m;

@page {
	margin: 5rem;
}

@page:left {
	@bottom-left {
		content: counter(pageNumber);
	}
}

.report {
	@include m.fadeIn(1000ms);
	@include m.shadow();

	background-color: v.$white;
	border-radius: 0.5rem;
}

.reportSettings {
	display: flex;
	justify-content: center;
	gap: 2rem;
	padding: 4rem 6rem;

	@include m.desktop {
		justify-content: end;
	}

	&__button {
		display: flex;
		align-items: center;
		color: v.$white;
		font-weight: 700;
		text-align: center;
		padding: 0.5rem 1rem;
		border-radius: 0.5rem;
		background-color: v.$primary;
		transition: background-color 300ms ease;

		&:hover {
			cursor: pointer;
			background-color: lighten($color: v.$primary, $amount: 8);
		}
	}

	&__icon {
		margin-right: 0.5rem;
	}
}

.reportPage {
	counter-reset: pageNumber;
	counter-increment: pageNumber;
	padding: 2rem;

	@include m.desktop {
		&--sidebarExpanded {
			max-width: calc(100vw - 26rem);
		}

		&--sidebarCollapsed {
			max-width: calc(100vw - 14rem);
		}

		@include m.transition(max-width, 300ms);
	}

	@include m.desktop {
		padding: 5rem;
	}

	@media print {
		padding: 0;
		background-color: white;
	}

	&__title {
		margin: 2rem auto;
		font-size: 1.6rem;
		font-weight: 700;
		text-align: center;
	}
}

.reportHeader {
	@include m.desktop {
		display: grid;
		grid-template-columns: 1fr 2fr 1fr;
	}

	@media print {
		display: grid;
		grid-template-columns: 1fr 2fr 1fr;
	}

	&__logo {
		text-align: center;
		color: v.$primary;
		margin: 1rem 2rem;
		font-weight: 400;
		font-style: italic;

		& span {
			font-weight: 900;
			font-size: 1.6rem;
		}

		@include m.desktop {
			text-align: end;
		}
	}

	&__date {
		text-align: center;
		grid-column: 3 / 4;
		font-size: 1.2rem;
	}
}

.tableContainer {
	overflow-x: scroll;
	overflow-y: hidden;

	@media print {
		overflow: hidden;
	}
}

.reportTable {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;

	font-size: 1.2rem;
	border-spacing: 0;

	&__header {
		border: solid 0.1rem v.$primary;
		background-color: lighten($color: v.$secondary, $amount: 40);
		border-radius: 2rem;
	}

	&__headerCell {
		padding: 0.5rem;
	}

	&__tableCell {
		padding-top: 1rem;
	}

	&__footerCell {
		border-top: solid 0.1rem v.$primary;
		padding-top: 1rem;
		font-weight: 700;
	}
}

.reportChart {
	margin: 4rem auto;
	width: 100%;
}
