@use "../base/variables" as v;
@use "../base/mixins" as m;

.detailForm {
	@include m.fadeIn(1000ms);

	background-color: v.$white;
	border-radius: 0.5rem;
	padding: 1rem;
	@include m.shadow();

	grid-column: 1 / 2;
	grid-row: 1 / 2;

	@include m.desktop {
		grid-column: 2 / 3;
	}

	&__title {
		margin-top: 2rem;
		font-size: 2.2rem;
		text-align: center;
	}

	&__buttonsGrid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 2rem;
		margin-top: 2rem;
	}

	&__addContactButton {
		grid-column: 1 / 3;
		margin: 0;
		color: v.$secondary;
		background-color: v.$white;
		border: 0.1rem solid v.$secondary;

		&:hover {
			background-color: lighten($color: v.$secondary, $amount: 45);
		}
	}

	&__icon {
		margin-right: 0.5rem;
		font-size: 1.6rem;

		&--mirror {
			transform: rotate(180deg);
		}
	}

	&__saveButton {
		grid-column: 1 / 3;
		margin: 0;
		background-color: v.$secondary;
		&:hover {
			background-color: darken($color: v.$secondary, $amount: 10);
		}

		@include m.fadeIn(1000ms);
	}

	&__closeButton {
		grid-column: 2 / 3;
		margin: 0;
	}

	&__deleteButton {
		margin: 0;
		background-color: v.$danger;

		&:hover {
			background-color: darken($color: v.$danger, $amount: 10);
		}
	}
}
