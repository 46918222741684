@use "../base/variables" as v;
@use "../base/mixins" as m;

.availableWaterCard {
	color: v.$primary;
	background-color: v.$white;
	border-radius: 0.5rem;
	padding: 2rem;
	@include m.shadow();
	margin-bottom: 2rem;

	&__title {
		display: flex;
		justify-content: center;
		margin-bottom: 1rem;
		font-size: 1.8rem;

		@include m.desktop {
			justify-content: start;
		}
	}

	&__section {
		display: flex;
		justify-content: space-around;
		gap: 1rem;
	}

	&__item {
		display: flex;
		flex-direction: column;
		font-size: 1.6rem;

		@include m.desktop {
			gap: 1rem;
			flex-direction: row;
		}
	}

	&__quantity {
		color: v.$secondary;
		font-weight: 700;
	}
}
